import React, { ReactNode } from "react";
import { ScreenCloud, ScreenData } from "../../ScreenCloudReactApp";
import BasicSite from "../Sites/BasicSite";
import SecureSite from "../Sites/SecureSite";
import { UUID } from "@screencloud/uuid";
// tslint:disable-next-line
const timestring = require("timestring");

export const MINIMUM_REFRESH_INTERVAL_SECONDS = 1;
export const MAXIMUM_REFRESH_INTERVAL_SECONDS = 24 * 60 * 60;

export interface Props {
  sc: ScreenCloud;
}

export enum SiteType {
  BASIC = "BASIC",
  BASIC_INTERNAL = "BASIC_INTERNAL",
  CLOUD = "CLOUD",
  CLOUD_INTERNAL = "CLOUD_INTERNAL",
  SECURE = "SECURE",
  SECURE_INTERNAL = "SECURE_INTERNAL"
}

export interface SiteConfig {
  siteId: UUID;
  type: SiteType;
  refreshIntervalSeconds?: number;
  scrollFactor?: number;
  scrollFrom?: number;
  scrollTo?: number;
  zoom?: number;
  url: string;
  timeZone: string;
}

export interface ScreenConfig {
  screenId: UUID;
  screenData?: ScreenData;
}

export interface State {
  preloading?: string;
  rendering?: string;
  error: any;
}

export default class Site extends React.Component<Props, State> {
  private siteConfig: SiteConfig;
  private screenConfig: ScreenConfig;

  constructor(props: Props) {
    super(props);

    try {
      this.siteConfig = this.validateAndReturnSiteConfig();
    } catch (err) {
      console.error(err);
      throw new Error("Invalid Site Config");
    }

    this.screenConfig = {
      screenId: props.sc.screenId,
      screenData: props.sc.screenData
    };
  }

  isValidUrl(url?: string): boolean {
    if (!url || url === "") {
      return false;
    }
    return true;
  }

  isValidRefreshInterval(refreshIntervalSeconds?: number): boolean {
    if (
      refreshIntervalSeconds &&
      (refreshIntervalSeconds < MINIMUM_REFRESH_INTERVAL_SECONDS ||
        refreshIntervalSeconds > MAXIMUM_REFRESH_INTERVAL_SECONDS)
    ) {
      return false;
    }
    return true;
  }

  validateAndReturnSiteConfig = (): SiteConfig => {
    const {
      siteId,
      type,
      refreshInterval,
      scrollFactor,
      zoom,
      url,
      timeZone
    } = this.props.sc.config;

    if (!type) {
      throw new Error("Invalid config: type not specified");
    } else if (
      (type === SiteType.BASIC || type === SiteType.BASIC_INTERNAL) &&
      !this.isValidUrl(url)
    ) {
      throw new Error(
        "Invalid config: url is required for a BASIC or BASIC_INTERNAL Site"
      );
    } else if (
      type !== SiteType.BASIC &&
      type !== SiteType.BASIC_INTERNAL &&
      !siteId
    ) {
      throw new Error(
        "Invalid config: siteId is required for CLOUD, CLOUD_INTERNAL, SECURE and SECURE_INTERNAL Sites"
      );
    }

    // calculate refresh interval which will be in format 5s or 10m etc
    let refreshIntervalSeconds;
    try {
      if (refreshInterval) {
        refreshIntervalSeconds = timestring(refreshInterval);
      }
    } catch {
      throw new Error("Invalid Site Config: unable to parse refreshInterval");
    }

    if (!this.isValidRefreshInterval(refreshIntervalSeconds)) {
      throw new Error("Invalid Site Config: invalid refresh interval");
    }

    const siteConfig: SiteConfig = {
      siteId,
      type,
      refreshIntervalSeconds,
      scrollFactor,
      zoom,
      url,
      timeZone
    };
    return siteConfig;
  };

  render(): ReactNode {
    if (
      this.siteConfig.type === SiteType.BASIC ||
      this.siteConfig.type === SiteType.BASIC_INTERNAL
    ) {
      return (
        <BasicSite
          sc={this.props.sc}
          screenConfig={this.screenConfig}
          siteConfig={this.siteConfig}
        />
      );
    } else {
      return (
        <SecureSite
          sc={this.props.sc}
          screenConfig={this.screenConfig}
          siteConfig={this.siteConfig}
        />
      );
    }
  }
}
