import React, { ReactNode, Component, ErrorInfo } from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import errorIcon from "../../assets/error.svg";
import "./ErrorBoundary.scss";

interface Props {
  sc: ScreenCloud;
}

interface RenderError {
  errorMessage: string;
  actionName: string;
  timestamp: Date;
}

interface State {
  errorBoundaryKey: number;
  error?: Error;
  hasError: boolean;
  eventId?: string;
  renderError?: RenderError;
  errorInfo?: ErrorInfo;
}

const MESSAGESIZE = 250;

function getFormattedTimestamp(date: Date): string {
  const formattedDate = date.toLocaleDateString();
  const hours = padLeft(date.getHours());
  const minutes = padLeft(date.getMinutes());
  const seconds = padLeft(date.getSeconds());
  return `${formattedDate} ${hours}:${minutes}:${seconds}`;
}

function padLeft(n: number): string {
  return n < 10 ? `0${n}` : `${n}`;
}

function truncate(str: string, n: number): string{
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorBoundaryKey: 0,
      error: undefined,
      renderError: undefined,
      errorInfo: { componentStack: "" }
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
      errorBoundaryKey: 1,
    });
    try {
      console.debug("Error caught by ErrorBoundary: ", error.message);

      const parsedError = JSON.parse(error.message);
      let errorMessage = parsedError;

      // Error object could have different strucutre (depending on the media gateway response)
      // Check before parsing nested error field
      if (parsedError.error) {
        errorMessage = JSON.parse(parsedError.error);
      }

      errorMessage.timestamp = new Date(errorMessage.timestamp * 1000);
      this.setState({ renderError: errorMessage });
      setTimeout(() => {
        this.setState({
          errorBoundaryKey: 0
        });
      }, 10 * 60 * 1000);
    } catch (e) {
      console.log("Viewer Error boundary failed: ", e);
    }
  }

  render(): ReactNode {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const isTickerTape = windowWidth > windowHeight * 7;
    if (this.state.hasError && this.state.errorBoundaryKey > 0) {
      return (
        <div className="">
          {this.props.children}
          <div className="error-container">
            <div className="left-panel">
              <img src={errorIcon} alt="Error icon" />
            </div>
            <div className="right-panel">
             <div>
               <h1>An error has occurred</h1>
               {!this.state.renderError && (
                <>
                  <p >Error Message: {truncate(this.state.error!.message, MESSAGESIZE) } </p>          
                  <p>More info: {truncate(JSON.stringify(this.state.error), MESSAGESIZE)} </p>
                </>
              )}
              {!!this.state.renderError && (
                <>
                  <p>Render Error: {truncate(this.state.renderError!.errorMessage, MESSAGESIZE)}</p>
                  <p>Errored at step: {truncate(this.state.renderError!.actionName, MESSAGESIZE)}</p>
                  <p>
                    Time of error:{"  "}
                    {getFormattedTimestamp(this.state.renderError!.timestamp)}
                  </p>
                </>
              )}
              {!isTickerTape && (
                <div className="error-actions">
                  <p>Please recreate your site instance and try again.</p>
                  <p>Contact ScreenCloud support if the error persists.</p>
                </div>
              )}
            </div>
          </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
