import React, { ReactNode } from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import { SiteConfig, ScreenConfig } from "../Sites/Site";

interface State {
  frameAStatus: FrameStatus;
  frameBStatus: FrameStatus;
  frameAUrl: string;
  frameBUrl: string;
  error: any;
}

export enum FrameStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PRELOADING = "PRELOADING"
}

export enum SiteType {
  BASIC = "BASIC",
  BASIC_INTERNAL = "BASIC_INTERNAL",
  CLOUD = "CLOUD",
  CLOUD_INTERNAL = "CLOUD_INTERNAL",
  SECURE = "SECURE",
  SECURE_INTERNAL = "SECURE_INTERNAL"
}

export interface TransformConfig {
  initialTransform: Transform;
  transforms: Transform[];
  duration: number;
  delay?: number;
}

export interface Transform {
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  offset: number;
}

interface Props {
  sc: ScreenCloud;
  siteConfig: SiteConfig;
  screenConfig: ScreenConfig;
}

const SCROLLFACTOR_PIXELS_PER_SECOND = 25;

export default class SecureSite extends React.Component<Props, State> {
  private preloadTimer: NodeJS.Timeout | undefined;
  private intervalTimer: NodeJS.Timeout | undefined;
  private pollingTimer: NodeJS.Timeout | undefined;
  private delayScrollTimer: NodeJS.Timeout | undefined;
  private sc: ScreenCloud;
  private transformConfig: TransformConfig;
  private iFrameA?: HTMLIFrameElement;
  private iFrameB?: HTMLIFrameElement;

  constructor(props: Props) {
    super(props);
    this.sc = props.sc;

    this.transformConfig = this.mapSiteConfigToTransformConfig();

    this.state = {
      frameAStatus: FrameStatus.ACTIVE,
      frameBStatus: FrameStatus.INACTIVE,
      frameAUrl: props.siteConfig.url || "",
      frameBUrl: "",
      error: undefined
    };
  }

  componentDidMount(): void {
    if (this.props.siteConfig.refreshIntervalSeconds) {
      this.setupIntervals(this.props.siteConfig.refreshIntervalSeconds);
    }

    if (
      this.props.siteConfig.scrollFactor &&
      this.props.siteConfig.scrollTo &&
      this.props.siteConfig.scrollTo > window.innerHeight
    ) {
      this.delayStartScroll();
    }
  }

  componentWillUnmount(): void {}

  mapSiteConfigToTransformConfig = (): TransformConfig => {
    const transforms: Transform[] = [];
    let animationTime = 0;

    const iFrameHeight = this.props.siteConfig.scrollTo
      ? this.props.siteConfig.scrollTo
      : window.innerHeight;
    const zoomLevel = this.props.siteConfig.zoom || 1;

    const contentHeight = iFrameHeight * zoomLevel;

    // keep image centered at the top of the page no matter the content size
    const offsetPositionX = 0;
    const offsetPositionY = (contentHeight - iFrameHeight) / 2;

    const initialTransform: Transform = {
      offset: 0,
      x: offsetPositionX,
      y: offsetPositionY,
      scaleX: zoomLevel,
      scaleY: zoomLevel
    };

    // if scrolling is in play work out scroll height and speed
    if (
      this.props.siteConfig.scrollFactor &&
      this.props.siteConfig.scrollTo &&
      this.props.siteConfig.scrollTo > window.innerHeight
    ) {
      const scrollFactor = this.props.siteConfig.scrollFactor;

      const scrollStart = this.props.siteConfig.scrollFrom || 0;
      const scrollEnd = this.props.siteConfig.scrollTo - window.innerHeight;
      const scrollHeight = scrollEnd - scrollStart;

      const pixelsPerSecond = SCROLLFACTOR_PIXELS_PER_SECOND * scrollFactor;
      animationTime = Math.ceil(scrollHeight / pixelsPerSecond) * 1000;

      const transform: Transform = {
        offset: 1.0,
        x: offsetPositionX,
        y: offsetPositionY - scrollHeight,
        scaleX: zoomLevel,
        scaleY: zoomLevel
      };
      transforms.push(initialTransform);
      transforms.push(transform);
    }

    const transformConfig: TransformConfig = {
      initialTransform: initialTransform,
      transforms: transforms,
      duration: animationTime,
      delay: 2000
    };

    return transformConfig;
  };

  setupIntervals = (refreshIntervalSeconds: number) => {
    console.debug(">>> setupIntervals Secure");

    const preloadWarmupMS = 5 * 1000;

    const refreshIntervalMS = refreshIntervalSeconds * 1000;

    setTimeout(
      this.setupPreloadInterval,
      refreshIntervalMS - preloadWarmupMS,
      refreshIntervalMS
    );

    this.intervalTimer = setInterval(this.displayFrame, refreshIntervalMS);
    console.debug("<<< setupIntervals Secure");
  };

  setupPreloadInterval = (refreshIntervalMS: number) => {
    this.preloadTimer = setInterval(this.startPreloadFrame, refreshIntervalMS);
    this.startPreloadFrame();
  };

  displayFrame = () => {
    if (this.state.frameAStatus === FrameStatus.ACTIVE) {
      this.setState({
        frameAStatus: FrameStatus.INACTIVE,
        frameBStatus: FrameStatus.ACTIVE,
        frameAUrl: "",
        frameBUrl: this.props.siteConfig.url
      });
    } else {
      this.setState({
        frameAStatus: FrameStatus.ACTIVE,
        frameBStatus: FrameStatus.INACTIVE,
        frameAUrl: this.props.siteConfig.url,
        frameBUrl: ""
      });
    }
    if (
      this.props.siteConfig.scrollFactor &&
      this.props.siteConfig.scrollTo &&
      this.props.siteConfig.scrollTo > window.innerHeight
    ) {
      this.delayStartScroll();
    }
  };

  startPreloadFrame = () => {
    if (this.state.frameAStatus === FrameStatus.INACTIVE) {
      this.setState({
        frameAStatus: FrameStatus.PRELOADING,
        frameBStatus: FrameStatus.ACTIVE,
        frameAUrl: this.props.siteConfig.url,
        frameBUrl: this.props.siteConfig.url
      });
    } else {
      this.setState({
        frameAStatus: FrameStatus.ACTIVE,
        frameBStatus: FrameStatus.PRELOADING,
        frameAUrl: this.props.siteConfig.url,
        frameBUrl: this.props.siteConfig.url
      });
    }
  };

  delayStartScroll = () => {
    this.delayScrollTimer = setTimeout(() => {
      console.log("Start when image loaded");
      this.startAnimation();
    }, this.transformConfig.delay || 0);
  };

  startAnimation = () => {
    let animationKeyframes = [];
    animationKeyframes = this.transformConfig.transforms.map(transform => {
      return {
        offset: transform.offset,
        transform: `translate(${transform.x}px, ${transform.y}px) scale(${
          transform.scaleX
        }, ${transform.scaleY})`
      };
    });

    if (this.iFrameA && this.state.frameAStatus === FrameStatus.ACTIVE) {
      this.iFrameA.animate(animationKeyframes, this.transformConfig.duration);
    } else if (this.iFrameB && this.state.frameBStatus === FrameStatus.ACTIVE) {
      this.iFrameB.animate(animationKeyframes, this.transformConfig.duration);
    }
  };

  render(): ReactNode {
    const screenDivStyle = {
      width: `${window.innerWidth}px`,
      height: `${window.innerHeight}px`,
      overflow: "hidden"
    };

    const frameHeight = `${
      this.props.siteConfig.scrollTo
        ? this.props.siteConfig.scrollTo
        : window.innerHeight
    }px`;
    const frameAStyle = {
      width: `${window.innerWidth}px`,
      height: this.state.frameAStatus === FrameStatus.ACTIVE ? frameHeight : 0,
      transform: `translate(${this.transformConfig.initialTransform.x}px, ${
        this.transformConfig.initialTransform.y
      }px) scale(${this.props.siteConfig.zoom || 1})`,
      display: this.state.frameAStatus === FrameStatus.ACTIVE ? "block" : "none"
    };

    const frameBStyle = {
      width: `${window.innerWidth}px`,
      height: this.state.frameBStatus === FrameStatus.ACTIVE ? frameHeight : 0,
      transform: `translate(${this.transformConfig.initialTransform.x}px, ${
        this.transformConfig.initialTransform.y
      }px) scale(${this.props.siteConfig.zoom || 1})`,
      display: this.state.frameBStatus === FrameStatus.ACTIVE ? "block" : "none"
    };

    return (
      <div style={screenDivStyle}>
        <iframe
          ref={iFrameA => (this.iFrameA = iFrameA || undefined)}
          style={frameAStyle}
          src={this.state.frameAUrl}
          title={"frameA"}
        />
        <iframe
          ref={iFrameB => (this.iFrameB = iFrameB || undefined)}
          style={frameBStyle}
          src={this.state.frameBUrl}
          title={"frameB"}
        />
      </div>
    );
  }
}
