export interface AppConfig {
  dsn: string;
  environment: "development" | string;
  mediaGatewayBaseUrl: string;
  secureSiteDefaultRefreshInterval: string;
}

const environment = `${REACT_APP_ENV}` || "development";

export const appConfig: Readonly<AppConfig> = Object.freeze({
  dsn: `${REACT_APP_DSN}`,
  environment,
  mediaGatewayBaseUrl: `${REACT_APP_MEDIA_GATEWAY_BASE_URL}`,
  secureSiteDefaultRefreshInterval: `${REACT_APP_SECURE_SITE_DEFAULT_REFRESH_INTERVAL}`
});
